import "./AfterPay.css";
import React from "react";
import { Helmet } from "react-helmet";

const AfterPay = () => {
  return (
    <div className="px-2">
      <article>
        <Helmet>
          <html lang="en" />
          <title>AfterPay - Dr Olga Palma</title>
          <meta
            name="description"
            content="Book with us now and pay for the visit over four equal fortnightly payments. No interest or fees if you pay on time!"
          />
          <link
            rel="alternate"
            href="https://drolgap.com.au/afterpay"
            hrefLang="en-AU"
          />
          <link rel="canonical" href="https://drolgap.com.au/afterpay" />
        </Helmet>

        <h2>What is AfterPay?</h2>
        <div className="horizontal-line"></div>
        <p>
          Book with us now and pay for the visit over four equal fortnightly
          payments. No interest or fees if you pay on time!
        </p>
        <p className="text-xl">SPEND UP TO $1,500</p>
        <p>You can use AfterPay for treatments up to $1,500.</p>

        <h2>How it works</h2>
        <div className="horizontal-line"></div>
        <h3>All you need is</h3>
        <ul className="mylist">
          <li>An Australian debit or credit card</li>
          <li>To be over 18 years of age</li>
          <li>To live in Australia</li>
        </ul>
        <h3>To use this service</h3>
        <ul className="mylist">
          <li>Book your appointment as normal</li>
          <li>
            Get the AfterPay App on your phone and Sign up - it takes less than
            2 minutes
          </li>
          <li>
            Before your visit, check your spend limit by selecting the barcode
            icon on your AfterPay App
          </li>
          <li>
            After your visit, generate a barcode to be scanned at the front desk
          </li>
          <li>
            Afterpay will debit the first fortnightly payment from your debit
            card
          </li>
          <li>
            On approval, your payment schedule will be displayed in the App
          </li>
        </ul>
        <h3>Other things to note</h3>
        <ul className="mylist">
          <li>
            The funds for the first fortnightly payment will need to be
            available at the time of your visit
          </li>
          <li>
            Your available spend limit will vary depending on whether you are a
            new or an existing AfterPay customer and depending on the orders you
            have pending
          </li>
          <li>
            Please ensure to check your spend limit before the appointment by
            selecting the barcode icon on your AfterPay App
          </li>
          <li>
            If you fail to make a payment on time, you will be charged a late
            payment fee of $10 with a further $7 fee added 7 days later if the
            payment is still unpaid.
          </li>
        </ul>
        <p>
          For full terms and conditions, please visit{" "}
          <a
            href="https://www.afterpay.com/en-AU/how-it-works"
            rel="noopener noreferrer"
            target="_blank"
          >
            AfterPay
          </a>
          .
        </p>
        <p>
          <br />
          <br />
        </p>
      </article>
    </div>
  );
};

export default AfterPay;
