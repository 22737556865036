import React from 'react';

import './Map.css';

const Map = () => {
  return <div className="--aspect-ratio:16/9 mb-12">
    <iframe
      title="map"
      width="100%"
      height="350"
      id="gmap_canvas"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1653.1913597765783!2d151.1027649568515!3d-34.03405247050182!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12c78572fb5e29%3A0xc23748655ead5508!2s505%2F531%20Kingsway%2C%20Miranda%20NSW%202228!5e0!3m2!1sen!2sau!4v1736559051851!5m2!1sen!2sau"
      frameBorder="0">
    </iframe>

    <div className="text-left py-2">
      <p className="text-xl font-semibold py-2">ADDRESS</p>
      <p className="text-lg"><i className="far fa-building"></i> (Level 5) Suite 505, 531 Kingsway, Miranda NSW 2228 <span className="mx-8"><i className="far fa-car"></i> - For your convenience, 3-hour free car parking at Westfield Miranda Shopping Centre</span></p>

    </div>
  </div>
}

export default Map;